import IMG1 from "./portfolio1.jpg";
import IMG2 from "./portfolio2.jpg";
import IMG3 from "./portfolio3.jpg";
import IMG4 from "./portfolio4.jpg";
import IMG5 from "./portfolio5.jpg";
import IMG6 from "./portfolio6.jpg";

import AV1 from "./avatar1.jpg";
import AV2 from "./avatar2.jpg";
import AV3 from "./avatar3.jpg";
import AV4 from "./avatar4.jpg";

export const portfolioItems = [
  {
    id: 1,
    img: IMG1,
    title: "Tattoo Artist Website",
    github: "https://github.com/raznoglaz1y",
    demo: "https://skinart.by",
  },
  {
    id: 2,
    img: IMG2,
    title: "Mobile APP UX/UI",
    github: "https://github.com/raznoglaz1y",
    demo: "https://github.com/raznoglaz1y",
  },
  {
    id: 3,
    img: IMG3,
    title: "Tesla Website APP",
    github: "https://github.com/raznoglaz1y/tesla-app",
    demo: "https://github.com/raznoglaz1y/tesla-app",
  },
  {
    id: 4,
    img: IMG4,
    title: "Bakery Shop UX/UI",
    github: "https://github.com/raznoglaz1y",
    demo: "https://github.com/raznoglaz1y",
  },
  {
    id: 5,
    img: IMG5,
    title: "DIY Shop UX/UI",
    github: "https://github.com/raznoglaz1y",
    demo: "https://7745.by/",
  },
  {
    id: 6,
    img: IMG6,
    title: "ECommerce Website",
    github: "https://github.com/raznoglaz1y/cottoncandy",
    demo: "https://github.com/raznoglaz1y/cottoncandy",
  },
];

export const testimonialsData = [
  {
    id: 1,
    avatar: AV1,
    name: "Renaldo Webber",
    review:
      "Not only was Alex’s work on-time and to spec, he pays attention to improve the aesthetics of the site as well as the functionality. Throughout the entire process he is responsive, and willing to work through issues as they arise. It’s obvious he takes tremendous pride in his work, and I wouldn’t hesitate to recommend or work with him again.",
  },
  {
    id: 2,
    avatar: AV2,
    name: "Ivan Stomp",
    review:
      "Working with Alex was better than expected and we had really high expectations. He is an incredibly talented developer but what really makes him stand out is his work ethic and steady approach. Time after time, and without us asking, he added enhancements and improvements that resulted in a better end product for us and our clients.",
  },
  {
    id: 3,
    avatar: AV3,
    name: "James Brough",
    review:
      "Ian's talent and experience proved immensely valuable for our latest web project. He was involved from the very beginning, providing advice and guidance even before we signed the proposal. The work we received from him was top-notch, thoroughly documented, and full of touches that show his commitment to quality. I can't recommend him highly enough.",
  },
  {
    id: 4,
    avatar: AV4,
    name: "Jane Mickley",
    review:
      "Ian provided top quality, thoughtful and high impact design work with outstanding attention to detail. His communication was excellent from the outset and showed a willingness to persevere until the perfect design was achieved. Obviously passionate about the work, a true professional. Wouldn’t hesitate to recommend.",
  },
];
